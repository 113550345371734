export enum TestGroupKey {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D'
}

export enum TestGroupSlug {
  Control = 'control',
  Test = 'test'
}

export interface TestGroup {
  keys: TestGroupKey[]
  slug: string
  description?: string
  isDefault?: boolean
}

export enum TestSlug {
  Example = 'exampleSlug',
  ShowNpsOnlyEndOfWeekAfterLunch = 'showNpsOnlyEndOfWeekAfterLunch',
  ConnectPrivateProjectCta = 'connectPrivateProjectCta',
  ConnectInviteeOnboardingVideo = 'connectInviteeOnboardingVideo',
  ConnectInviteeConnectView = 'connectInviteeConnectView'
}

export interface Test {
  startDate: Date
  groups: TestGroup[]
}

const NOT_LIVE = new Date('3000-01-01')
const LIVE = new Date('2000-01-01')

export const abTests: Map<TestSlug, Test> = new Map([
  [
    TestSlug.ShowNpsOnlyEndOfWeekAfterLunch,
    {
      startDate: LIVE,
      groups: [
        {
          keys: [TestGroupKey.A, TestGroupKey.B],
          slug: TestGroupSlug.Control,
          description: 'Control group: Show NPS whenever',
          isDefault: true
        },
        {
          keys: [TestGroupKey.C, TestGroupKey.D],
          slug: TestGroupSlug.Test,
          description: 'Test group: Show NPS only at the end of the week after lunch'
        }
      ]
    }
  ],
  [
    TestSlug.ConnectPrivateProjectCta,
    {
      startDate: LIVE,
      groups: [
        {
          keys: [TestGroupKey.A, TestGroupKey.B],
          slug: TestGroupSlug.Control,
          description: 'Control group: + Project',
          isDefault: true
        },
        {
          keys: [TestGroupKey.C, TestGroupKey.D],
          slug: TestGroupSlug.Test,
          description: 'Test group: + Create private project'
        }
      ]
    }
  ],
  [
    TestSlug.ConnectInviteeOnboardingVideo,
    {
      startDate: LIVE,
      groups: [
        {
          keys: [TestGroupKey.C, TestGroupKey.D],
          slug: TestGroupSlug.Control,
          description: 'Control group: Intercom Tour',
          isDefault: true
        },
        {
          keys: [TestGroupKey.A, TestGroupKey.B],
          slug: TestGroupSlug.Test,
          description: 'Test group: Onboarding video'
        }
      ]
    }
  ],
  [
    TestSlug.ConnectInviteeConnectView,
    {
      startDate: LIVE,
      groups: [
        {
          keys: [TestGroupKey.A, TestGroupKey.B],
          slug: TestGroupSlug.Control,
          description: 'Control group: Version A of Banner',
          isDefault: true
        },
        {
          keys: [TestGroupKey.C, TestGroupKey.D],
          slug: TestGroupSlug.Test,
          description: 'Test group: Version B of Banner'
        }
      ]
    }
  ]
])
